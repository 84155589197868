import OneSelectUI from "../inputs/one-select";
import '../../styles/elements/fields-selector.scss'
import { ReactComponent as RightArrow } from '../../assets/right-arrow.svg';
import {useCallback, useEffect, useState} from "react";

function AllFieldsSelector({dataToFieldMapping, setDataToFieldMapping, availableFieldsInSmartProcess, comment}) {

    // const getCurrentFieldByMapping = useCallback((el) => {
    //     return availableFieldsInSmartProcess.filter(
    //         (field) => el.b24_system_name === field.b24_system_name
    //     )[0]
    // }, [availableFieldsInSmartProcess])

    // console.log('dataToFieldMapping', dataToFieldMapping)

    // TODO: При изменении смарт-процесса визуально остаются выбранные поля со старого смарт процесса. Пофиксить

    return (
        <div className={'fields-selector'}>
            <div className={'fields-selector-title'}>
                Выберите поля, в которые будут заноситься данные
            </div>
            <div className={'fields-selector-comment'}>
                {comment}
            </div>
            <div className={'fields-selector-list'}>
                {dataToFieldMapping.map(el =>
                    <div className={'field-selector'}>
                        <div className={'field-selector-title'}>
                            {el.ui_name}
                        </div>
                        <div className={'field-selector-arrow'}>
                            <RightArrow/>
                        </div>
                        <div className={'field-selector-selector'}>
                            <OneSelectUI
                                isClearable={true}
                                selectionOptions={availableFieldsInSmartProcess.filter(
                                    field => el.required_field_types.includes(field.field_type)
                                )}
                                selectedOption={
                                    availableFieldsInSmartProcess.filter(
                                        (field) => el.b24_system_name === field.b24_system_name
                                    )[0]
                                }
                                setSelectedOption={(selectedField) => setDataToFieldMapping(
                                    prevMapping => {
                                        // Определяем индекс изменяемых данных массиве маппингов
                                        let index_of_current_mapping_obj;
                                        for (let i in prevMapping) {
                                            if (prevMapping[i].system_name === el.system_name) {
                                                index_of_current_mapping_obj = i
                                            }
                                        }
                                        // Копируем предыдущий маппинг
                                        let newMapping = JSON.parse(JSON.stringify(prevMapping))
                                        // В новом маппинге устанавливаем указанное поле
                                        if (selectedField) {
                                            newMapping[index_of_current_mapping_obj].b24_system_name = selectedField.b24_system_name
                                        } else {
                                            newMapping[index_of_current_mapping_obj].b24_system_name = null
                                        }
                                        return newMapping
                                    }
                                )}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AllFieldsSelector;