import '../styles/elements/header.scss';
import Logo from '../assets/logo.png'
import WFLogo from '../assets/wf-logo.png'

export default function Header() {
    return (
        <header>
            <div className={'logo_and_name'}>
                <img src={Logo}></img>
            </div>
            <div className={'right-elements'}>
                <button className='help-button'
                        onClick={() => window.open(
                            'https://www.youtube.com/watch?v=jXSzwIqOPik&list=PLQReu4zUp5f1LNyxEpAJuCE4FUIpI4IlZ&ab_channel=Вебформат',
                            '_blank'
                        )}
                >Гайд по использованию</button>
                <button className='support-button'
                        onClick={() => window.open(
                            'https://t.me/webformat_ltd',
                            '_blank'
                        )}
                >
                    Чат поддержки
                </button>
                {/*<button className={'settings'}>*/}
                {/*    <SettingsLogo/>*/}
                {/*</button>*/}
                <div className={'wf-logo'}>
                    <a href='https://www.webformat.ru' target={'_blank'}>
                        <img className={'webformat-logo'} src={WFLogo}></img>
                    </a>
                </div>
            </div>
        </header>
    )
}